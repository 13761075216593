import { Component, Input, OnInit,ViewChild, ElementRef, Output, EventEmitter, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../../service/common.service';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { TranslateService } from '../../manual/i18n/translate.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
@Component({
  selector: 'app-customer-information-modal',
  templateUrl: './customer-information-modal.component.html',
  styleUrls: ['./customer-information-modal.component.css']
})
export class CustomerInformationModalComponent implements OnInit {
  @ViewChild('CustomerEditModalClose') CustomerEditModalClose: ElementRef;
  sellerInfo: any;
  IdentityType: any;
  SelectedPassportType: boolean;
  IsSubmit = false;
  ValidateAddressLabel: boolean;
  isshow: boolean = false;
  IsCommercialSellerConfirm: any;
  IsCommercialSeller: any;
  formcustomer: FormGroup;
  public userInfo: any;
  public translatePipe: any;
  public TempFormCustomerData:any;
  @Input() SellerInfo;
  @Output() EditCustomerDetail = new EventEmitter<any>();
  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService, private _service: CommonService,
    private toster: ToastrService, private translate: TranslateService, private router: Router,public activeModal: NgbActiveModal,
    @Inject(DOCUMENT) private document: any) {
      this.translatePipe = new TranslatePipe(translate);
    this.formcustomer = fb.group({
      IMEI: [
        null,
        // Validators.compose([

        // ])
      ],
      FirstName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(40),
          _service.noWhitespaceValidator
        ])
      ],
      // IdentificationNo: [
      //   null,
      //   Validators.compose([
      //     Validators.required,
      //     Validators.maxLength(20),
      //     _service.noWhitespaceValidator,
      //     Validators.pattern("^[0-9]*$")
      //   ])
      // ],
      // ContactName: [
      //   null,
      //   Validators.compose([
      //     Validators.required,
      //     Validators.maxLength(50),
      //     _service.noWhitespaceValidator
      //   ])
      // ],
      PESEL: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(11),
          Validators.minLength(11),
          _service.noWhitespaceValidator,
          // _service.VerifyPESEL,
          Validators.pattern("^[0-9]*$")
        ])
      ],
      Street: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(120),
          _service.noWhitespaceValidator
        ])
      ],
      ApartmentNo: [
        null,
        Validators.compose([
          Validators.maxLength(10)
        ])
      ],
      BuildingNo: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(25),
          _service.noWhitespaceValidator
        ])
      ],
      LastName: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(40)
        ])
      ],
      IdentityType: [
        '0',
        Validators.compose([
          Validators.required,
          Validators.maxLength(100)
        ])
      ],
      City: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(50),
          _service.noWhitespaceValidator
        ])
      ],
      postCode: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(6),
          _service.noWhitespaceValidator
        ]),

      ],
      IDNo: [
        null,
        Validators.compose([
          Validators.required,
          Validators.maxLength(9),
          _service.noWhitespaceValidator,
          // _service.ValidateIDNO,
          Validators.pattern("^[a-zA-Z0-9]*$"),


        ]
        ),

      ],
      CompanyName: [
        null,
        Validators.compose([
          Validators.maxLength(50),
        ])
      ],
      NIP: [
        null,
        Validators.compose([
          Validators.maxLength(10),
          // _service.ValidateNIP
        ])

      ],
      IsCommercialSeller: [
        false
      ],
      CustomerID: [0]
    },
      //{ validator: equalValueValidator('EmailAddress', 'ConfirmEmailAddress') }  // key is to validate on the form group
    );
  }

  ngOnInit() {
    this.IsSubmit = true;
    this.userInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    this.editCustomerinfo();
  }

  editCustomerinfo() {
    debugger
    this.isshow = true;
    this.IsCommercialSeller = this.sellerInfo.IsCommercialSeller;
    this.sellerInfo.IdentityType = !this.sellerInfo.IdentityType ? '0': this.sellerInfo.IdentityType;
    this.getIdentityType(this.userInfo.EnterprisePartnerID);
    this.formcustomer.controls['FirstName'].setValue(this.sellerInfo.FirstName=="null"?"":this.sellerInfo.FirstName);
    this.formcustomer.controls['PESEL'].setValue(this.sellerInfo.PESEL=="null"?"":this.sellerInfo.PESEL);
    this.formcustomer.controls['BuildingNo'].setValue(this.sellerInfo.BuildingNo=="null"?"":this.sellerInfo.BuildingNo);
    this.formcustomer.controls['postCode'].setValue((this.sellerInfo.postCode === "null" ? "": this.sellerInfo.postCode)|| (this.sellerInfo.PostCode === "null" ? "": this.sellerInfo.PostCode));
    this.formcustomer.controls['IdentityType'].setValue(this.sellerInfo.IdentityType=="null"?"":this.sellerInfo.IdentityType);
    this.formcustomer.controls['LastName'].setValue(this.sellerInfo.LastName=="null"?"":this.sellerInfo.LastName);
    this.formcustomer.controls['Street'].setValue(this.sellerInfo.Street=="null"?"":this.sellerInfo.Street);
    this.formcustomer.controls['ApartmentNo'].setValue(this.sellerInfo.ApartmentNo=="null"?"":this.sellerInfo.ApartmentNo);
    this.formcustomer.controls['City'].setValue(this.sellerInfo.City=="null"?"":this.sellerInfo.City);
    this.formcustomer.controls['IDNo'].setValue(this.sellerInfo.IDNo=="null"?"":this.sellerInfo.IDNo);
    this.formcustomer.controls['NIP'].setValue(this.sellerInfo.NIP=="null"?"":this.sellerInfo.NIP);
    this.formcustomer.controls['CompanyName'].setValue(this.sellerInfo.CompanyName=="null"?"":this.sellerInfo.CompanyName);

  }
  resetprevious() {
    this.activeModal.dismiss('Cross click');
  }



  UpdateCustomerInfo() {
    debugger
    this.sellerInfo = JSON.parse(sessionStorage.getItem('SellerInfo'));
    if (this.formcustomer.valid) {
      this.spinner.show();
  
      this.IsSubmit = false;

      let parameters = this.formcustomer.value;

      const reBody = {
        FirstName: parameters.FirstName,
        PostCode: parameters.postCode,
        IdentityType: parameters.IdentityType,
        LastName: parameters.LastName,
        City: parameters.City,
        PESEL: parameters.PESEL,
        ApartmentNo: parameters.ApartmentNo,
        BuildingNo: parameters.BuildingNo,
        IDNo: parameters.IDNo,
        Street: parameters.Street,
        CompanyName: parameters.CompanyName,
        NIP: parameters.NIP,
        TempID: this.sellerInfo.ID

      };


      this._service.postmethod(reBody, 'api/Update-Customer-Details').then(result => {
        if (result.IsSuccess || result.Data.Message === 'Success') {
          this.TempFormCustomerData=this.formcustomer.value;
          this.TempFormCustomerData.IsCustomerformFilled=true;
          this.TempFormCustomerData.PostCode = this.formcustomer.value.postCode;
          sessionStorage.setItem('SellerInfo', JSON.stringify(this.TempFormCustomerData));
          sessionStorage.setItem('cusdetails', JSON.stringify(this.TempFormCustomerData));
          this.EditCustomerDetail.emit(this.TempFormCustomerData);
          this.spinner.hide();
          if (result.Data.Type === 'Update') {
            //this.getTempRetailOrderScanData()    
         this.toster.success(this.translatePipe.transform("Customer_updated"), this.translatePipe.transform("success"));   // this.ResetQuotedPrice();
            this.activeModal.dismiss('Cross click');
            this.document.body.classList.remove('editable-modal');
          }
        } else {
          this.toster.error(this.translatePipe.transform("An_Error_Occured"), 'Error');
          // AssetProcessData.Assets = null;
        }

        this.spinner.hide();
      });
    }
    else {
      this.IsSubmit = true;

    }
    // this.AssetProcessData.ErrorMessage = '';



    // this.Reset(); 
  }
  openSellerConfirmPopUp(IsCommercialSeller) {
    this.IsCommercialSellerConfirm = IsCommercialSeller;
    // this.btnSellerChangePopUp.nativeElement.click();
  }
  getIdentityType(EnterprisePartnerID) {
    this.spinner.show();
    this._service.getMethod('api/GetIdentityType/' + EnterprisePartnerID).then(data => {

      if (data.length > 0) {
        this.IdentityType = data;
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.router.navigate(['/error']);
    });
  }
  validateIdentityType(value) {
    debugger
    if (value != null && value == '2') {
      this.SelectedPassportType = true;
      sessionStorage.setItem('SelectedPassportType', JSON.stringify(this.SelectedPassportType));
    }
    else if (value != null && value == '3') {
      this.SelectedPassportType = false;
      sessionStorage.setItem('SelectedPassportType', JSON.stringify(this.SelectedPassportType));
    }

  }
  Remove_label_focus(event) {
    if (event.targetmethod && event.targetmethod.value === "") {
      event.currentTargetmethod.classList.remove('label-focus');
    }
  }
  RemoveAddressValidation() {
    this.ValidateAddressLabel = false;
  }
  fnPostCode(event) {
    const pattern = /^[0-9]+$/;
    const inputChar = String.fromCharCode(event.charCode);
    var value = event.target.value;
    if (!inputChar.match(pattern)) {
      event.preventDefault();
    } else {
      if (value && !isNaN(event.key) && value.length == 2) {
        this.formcustomer.patchValue({
          postCode: value + '-'
        });
      }
    }
  }

  _AllowNumberOnly(event: any) {

    const pattern = /[0-9()\b\0\-]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


}

