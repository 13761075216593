import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SocketService } from '../../service/socket/socket.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/service/common.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslatePipe } from '../../manual/i18n/translate.pipe';
import { TranslateService } from '../../manual/i18n/translate.service';

@Component({
  selector: 'app-esignature',
  templateUrl: './esignature.component.html',
  styleUrls: ['./esignature.component.css']
})
export class ESignatureComponent implements OnInit {
  DigitalSigQrcode: any;
  OrderData: any;
  public translatePipe: any;
  public SalesContractData: any;
  img: any;
  userInfo: any;
  @Output() emitService = new EventEmitter();
  constructor(private socketService: SocketService, private spinner: NgxSpinnerService, private _service: CommonService,
    private toster: ToastrService, public activeModal: NgbActiveModal,   private translate: TranslateService) {
   
  
        this.translatePipe = new TranslatePipe(translate);

     }

  ngOnInit() {
    this.userInfo = JSON.parse(sessionStorage.getItem('StoreInfo'));
    console.log("Order data = "+this.OrderData);
  }
  ngAfterViewInit() {
    this.socketService.ESignComplete_back().subscribe((resp: any) => {
      debugger
      if (this.OrderData.OrderDetailID == resp.OrderDetailID) {
        this.img = resp.ESignBase64String
        this.SalesContractData = resp
      }
    });
  }

  UploadContractSignedPdf(item) {
    debugger
    this.spinner.show();
    this.SalesContractData.IsCommercialSeller = item.IsCommercialSeller;
    this.SalesContractData.ESignBase64String = this.img;
    // this.SalesContractData.Warehouse = this.userInfo.Warehouse;
    // this.SalesContractData.Address1 = this.userInfo.Address1;
    // this.SalesContractData.Address2 = this.userInfo.Address2;
    // this.SalesContractData.Address3 = this.userInfo.Address3;
    // this.SalesContractData.City = this.userInfo.City;
    // this.SalesContractData.PostCode = this.userInfo.PostCode
    // this.SalesContractData.WarehouseRecievedDay = this.userInfo.WarehouseRecievedDay
    // this.SalesContractData.QuotedPrice = item.QuotedPrice
    // this.SalesContractData.ActualPrice = item.ActualPrice
    // this.SalesContractData.PercentageDeduction = item.PercentageDeduction
    // this.SalesContractData.CreatedDate = item.OrderCreatedOn
    // this.SalesContractData.IdentityValue = item.IdentityType
    this.SalesContractData.ESignBase64String = this.img;
    this.SalesContractData.FirstName = item.FirstName;
    this.SalesContractData.CustomerLastName = item.CustomerLastName;
    this.SalesContractData.CompanyName = item.CompanyName;
    this.SalesContractData.IdentityType = item.IdentityType;
    this.SalesContractData.TempOrderID = item.ID;
    this.SalesContractData.Make = item.Make;
    this.SalesContractData.Model = item.Model;
    this.SalesContractData.Capacity = item.Capacity == 'N/A' ? '' : item.Capacity;
    this.SalesContractData.SerialNumber = item.SerialNumber;
    this.SalesContractData.IMEI = item.IMEI;
    this.SalesContractData.Street = item.Street;
    this.SalesContractData.BuildingNo = item.BuildingNo;
    this.SalesContractData.ApartmentNo = item.ApartmentNo;
    this.SalesContractData.PostCode = item.PostCode;
    this.SalesContractData.IdentificationNo = item.IdentificationNo;
    this.SalesContractData.IDNo = item.IDNo;
    this.SalesContractData.PESEL = item.PESEL;
    this.SalesContractData.City = item.City;
    this.SalesContractData.QuotedPrice = item.QuotedPrice;
    this.SalesContractData.ContactName = item.ContactName;
    this.SalesContractData.EnterprisePartner = this.userInfo.EnterprisePartner;
    this.SalesContractData.EnterprisePartnerID = this.userInfo.EnterprisePartnerID;
    this.SalesContractData.CurrencySymbol = this.userInfo.CurrencySymbol;
    this.SalesContractData.Store = this.userInfo.Store;
    this.SalesContractData.StoreID = this.userInfo.StoreID;
    this.SalesContractData.OrderDetailID = this.OrderData.OrderDetailID;
    this.SalesContractData.Name = this.userInfo.Name;
    this.SalesContractData.LastName = this.userInfo.LastName;
    this.SalesContractData.StoreCity = this.userInfo.City;
    this.SalesContractData.NIP = item.NIP;
    this.SalesContractData.OrderCreatedOn = item.OrderCreatedOn;
    this._service.Add_Esign(this.SalesContractData, 'api/upload-contract-signed-pdf').then(result => {
      if (result.IsSuccess) {
        this.activeModal.dismiss();
       
        this.toster.success(this.translatePipe.transform('Contract_uploaded_successfully'), this.translatePipe.transform('Contract_uploaded'));
        this.emitService.emit(true);
      } else {
        console.log('Error:', result);
        this.toster.error(result, 'Error Occured');
      }
      this.spinner.hide();
    })
      .catch(error => {
        this.spinner.hide();
        console.log(error);
        this.toster.error(error, 'Error Occured');
      });
  }
}
